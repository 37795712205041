.searchbox-container {
  input {
    background: url('../img/picto-loupe-blanc.png') center left no-repeat !important;
  }

  &-lite {
    input {
      background: url('../img/picto-loupe-noir.png') center left no-repeat !important;
    }
  }
}