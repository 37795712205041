@import url(http://fonts.googleapis.com/css?family=Open+Sans:300,700italic,400,300italic,700,400italic);
html.no-opacity .overlay-wrapper {
  background: url('../img/background_white_08.png') repeat; }
html.no-opacity .overlay-content {
  background: url('../img/background_blue_08.png') repeat; }

.nav {
  border-bottom: 2px solid #e6e6e6; }
  .nav-origin {
    border-bottom: none; }

#top-destination {
  background-color: #00aeef; }

form .select-container {
  background: url('../img/arrow-down.png') center right no-repeat; }

.searchbox-container input {
  background: url('../img/picto-loupe-blanc.png') center left no-repeat !important; }
.searchbox-container-lite input {
  background: url('../img/picto-loupe-noir.png') center left no-repeat !important; }

